:root {
  color-scheme: light only;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent !important;
}

.ReactModal__Body--open {
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Permanent Marker";
  src: url("./assets/fonts/PermanentMarker-Regular.ttf") format("truetype");
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: "Crimson Text";
  src: url("./assets/fonts/CrimsonText-Italic.ttf") format("truetype");
  font-weight: bolder;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Homemade Apple";
  src: url("./assets/fonts/HomemadeApple-Regular.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Shrikhand";
  src: url("./assets/fonts/Shrikhand-Regular.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Courgette";
  src: url("./assets/fonts/Courgette-Regular.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: "Lora";
  src: url("./assets/fonts/Lora-Italic.ttf") format("truetype");
  font-style: bold;
  font-display: swap;
}